{
  "name": "@the-oz/app-swatches",
  "version": "3.0.2-RC.8",
  "description": "",
  "module": "dist/module.js",
  "browser": "dist/index.js",
  "targets": {
    "module": {
      "source": "src/lib.js",
      "optimize": true
    },
    "browser": {
      "source": "src/index.js",
      "optimize": true,
      "includeNodeModules": ["jquery"]
    }
  },
  "scripts": {
    "watch": "parcel watch",
    "build": "parcel build",
    "prepack": "npm run build"
  },
  "author": "The Oz",
  "license": "ISC",
  "devDependencies": {
    "parcel": "^2.7.0"
  },
  "dependencies": {
    "jquery": "^3.6.1"
  }
}
