import { version } from '../package.json';

export function init($ = required('jquery')) {
  return {
    run: () => {
      console.log(`*** The Oz Swatches app - version: ${version}`);
      onCustomSwatchClicked();
      document.addEventListener('cs-rerender', onCustomSwatchClicked);
    }
  }

  function reRenderVP() {
    document.dispatchEvent(new Event("vp-rerender"));
  }

  function populateProductBlock(productJSON, $productContainer, handle) {
    let $newProductBlock = $(productJSON.product_block);
    // Add the "active" class to the currently selected swatch
    $newProductBlock.find('.oz-related-products-swatches--li').removeClass('active');
    $newProductBlock.find(`.oz-related-products-swatch[data-handle="${handle}"]`).parent().addClass('active');
    // Add content of style attribute
    const currentStyleAttr = $productContainer.attr('style');
    $newProductBlock.attr('style', currentStyleAttr);
    // Replace product block content with new content
    $productContainer.replaceWith($newProductBlock);
    // Throw event when HTML has been replaced
    document.dispatchEvent(new Event('cs-rerendered'));
  }

  function getProductHtml(handle, extraParams, $productContainer) {
    const query = `/search?q=${handle}|${extraParams}&view=oz-related-product-block-html`;
    $.getJSON(query, {})
      .done(function (productJSON) {
        populateProductBlock(productJSON, $productContainer, handle)
        onCustomSwatchClicked();
        reRenderVP();
      })
      .fail(function (currentAjaxRequest, textStatus, error) {
        var err = textStatus + ', ' + error + textStatus;
        console.log('Get swatch failed: ' + err);
      });
  }

  function onCustomSwatchClicked() {
    $('.oz-collection-swatch.oz-related-products-swatch:not(.oz-swatch-initialised)')?.on('click', function (e){
      const $clickedSwatch = $(this).parent();
      const $productContainer = $clickedSwatch.closest('.cs-product-container');
      const extraParams = $productContainer.attr('data-cs-params');
      const clickedProductHandle = $(this).data('handle');
      if (!$clickedSwatch.hasClass('active') && $productContainer) {
        getProductHtml(clickedProductHandle, extraParams, $productContainer);
      }
    })?.addClass('oz-swatch-initialised');
  }
}

function required(name) {
  throw new Error(`${name} required`)
}
